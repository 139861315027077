import { render, staticRenderFns } from "./GreenList.vue?vue&type=template&id=3944fcd8&scoped=true"
import script from "./GreenList.vue?vue&type=script&lang=js"
export * from "./GreenList.vue?vue&type=script&lang=js"
import style0 from "./GreenList.vue?vue&type=style&index=0&id=3944fcd8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3944fcd8",
  null
  
)

export default component.exports