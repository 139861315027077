<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-26 18:52:00
-->
<template>
  <div class="organization" :style="'height: ' + innerHeight1 + 'px'">
    <p class="font_sy Impact">GSC Action Real Estate Enterprises Member Directory</p>
    <p class="font_sy sy1 Impact" v-if="$store.state.language === 'cn'">绿链行动房企名单</p>
    <div class="flex_by">
      <div class=" boxol">
        <p>。</p>
        <div></div>
        <p>。</p>
        <div></div>
        <p>。</p>
      </div>
      <div class=" Carousel ">
        <div class="h">
          <div class="h" id="swiper2">
            <div class="swiper-wrapper h">
              <!-- <div class="swiper-slide h">
                  <img class="h" src="@/assets/swipre_01_.jpg" alt="">
                </div>
                <div class="swiper-slide">
                  <img class="h" src="@/assets/swipre_02.jpg" alt="">
                </div> -->
              <div class="swiper-slide">
                <img class="h" src="@/assets/swipre_03.jpg" alt="">
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class=" boxol——3">
        <div>
          <p class="boxol_3_1" v-if="$store.state.language === 'cn'">绿链行动房企名单</p>
          <p class="boxol_3_1" v-else>GSC action real estate enterprises member directory</p>
          <!-- <p class="boxol_3_2" v-if="$store.state.language === 'cn'">截至目前加入绿链行动房企共100多家</p> -->
          <!-- <p class="boxol_3_2" v-else>More than 100 real estate enterprises have joined the GSC action since its launch</p> -->
          <p class="boxol_3_2" v-if="$store.state.language === 'cn'">截至目前加入绿链行动房企共90多家</p>
          <p class="boxol_3_2" v-else>More than 90 real estate enterprises have joined the GSC action since its launch</p>
          <div>
            <a class="fx" href="javascript:;" @click="addck">
              <p class="white" v-if="$store.state.language === 'cn'">查看详情</p>
              <p class="white" v-else>More information</p>
              <p class="white icon_1"><i class="icon_1 iconfont icon-anniu-jiantouxiangyou_o"></i></p>
            </a>
          </div>
        </div>

      </div>
    </div>
    <!-- ---------------------- -->
    <div class="carouse_1l ">
      <div class="flex_lt">
        <div @click="add" class="swiper-button-prev_1">
          <i class="font_s iconfont  icon-jiantou_qiehuanzuo">
          </i>
        </div>
        <div class="wes">
          <div class="swiper-pagination_1 wes"></div>
        </div>
        <div @click="add" class="swiper-button-next_1">
          <div class="rotate"><i class="font_s iconfont  icon-jiantou_qiehuanzuo"></i></div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import '@/css/organization.less'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      mySwiper: null,
      addNum: 1,
      innerHeight1: null
    }
  },
  
  mounted() {
    this.innerHeight1 = window.innerHeight
    window.onresize = () => {
      this.innerHeight1 = window.innerHeight
    }
    console.log(12);
    const _this = this
    this.mySwiper = new Swiper('#swiper2', {
      // direction: 'vertical', // 垂直切换选项
      autoplay: true,
      loop: true, // 循环模式选项

      // // 如果需要分页器
      pagination: {
        el: '.swiper-pagination_1',
        clickable: true,
        renderBullet: function (index, className) {
          console.log(className);
          const num = index + 1
          return '<p @click="addNum= ' + num + '" class="' + className + '">' + (index + 1) + '</p>';
        },
      },
      on: {
        slideChangeTransitionStart: function () {

          let num = this.activeIndex
          // console.log(num == 4);
          if (num == 4) {
            num = 1
          }
          if (num == 0) {
            num = 3
          }
          _this.addNum = num;
          //  alert(num)
        },
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next_1',
        prevEl: '.swiper-button-prev_1',
      },

      // 如果需要滚动条

    })
    this.mySwiper.disable()
  },
  computed: {
    ...mapGetters(['typeLists'])
  },
  methods: {
    ...mapActions(['changeType', 'changeSubType']),
    add() {
      // setTimeout(() => {
      //   this.addNum = this.mySwiper.realIndex + 1
      //   console.log(this.addNum);
      // }, 200)
    },
    addck() {
      console.log(this.addNum, '123');
      this.changeType(this.typeLists[0]);
      this.changeSubType(this.typeLists[0].subMap.find(v=>v.typeName === '房企名录'));
      this.$router.push({ name: "MemberDirectory" })
    }
  }
}
</script>
<style lang="less" scoped >
.organization {
  position: relative;
  height: 100%;
}

.flex_lt {
  display: flex;
  justify-content: space-between;
}

.font_sy {
  padding-top: 50px;
  font-weight: 700;
  font-size: 50px;
  color: #fff;
  position: relative;
  top: 4%;
}

.sy1 {
  margin-bottom: 30px;
  padding-top: 10px;
}

.flex_by {
  position: relative;
  top: 10%;
  display: flex;
  height: 80%;

  .boxol {
    width: 50px;
    height: 533px;
  }

  .Carousel {
    width: 1064px;
    height: 600px;
    overflow: hidden;
  }

  .boxol——3 {
    flex: 1;
    padding-left: 60px;
  }

  .boxol p {
    color: #fff;
    font-size: 50px;
    font-family: 700;
  }

  .boxol div {
    margin-left: 8px;
    height: 120px;
    border-left: 2px solid #fff;
    position: relative;
    top: 10px;
  }
}

.boxol_3_1 {
  display: inline-block;
  margin-top: 80px;
  margin-left: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.boxol_3_2 {
  color: #fff;
  margin-top: 30px;
  margin-left: 30px;
  padding-bottom: 20px;
  font-size: 17px;
}

.fx {
  display: flex;
  margin-top: 100px;
  margin-left: 30px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: normal;
}

.icon_1 {
  position: relative;
  top: -10px;
  width: 30px;
  font-size: 50px;
}

.carouse_1l {
  position: absolute;
  bottom: 10%;
  right: 2%;
}

.rotate {
  display: inline-block;
  transform: rotate(180deg);
  transform: rotateY(180deg);
}

.swiper-pagination_1 {
  span {
    display: inline-block;
    width: 100px;
  }
}

.wes {
  margin-top: -2px;
}

.font_s {
  font-size: 50px !important;
  color: #8b8b8b;
}

.swiper-button-next_1,
.swiper-button-prev_1 {
  margin-top: 8px;
}

.h {
  height: 100%;
  width: 100%;
}
</style>